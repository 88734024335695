import { render, staticRenderFns } from "./ProductGroupsListing.vue?vue&type=template&id=107b5dac&scoped=true&"
import script from "./ProductGroupsListing.vue?vue&type=script&lang=js&"
export * from "./ProductGroupsListing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "107b5dac",
  null
  
)

export default component.exports